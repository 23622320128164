@import './vars.scss';
@import './mixins.scss';

.radioButtons .ant-radio {
    display: none;
}

.ant-popover-inner-content {
    padding: 10px;
}

.radioButtons {
  .ant-radio-group {
    display: flex !important;
    flex-wrap: wrap;
  }

  .ant-radio-wrapper {
    @include subtypeRadio($color-gray, $color-weak-gray);
    height: 30px !important;
    cursor: pointer;
    margin: 2px;

    display: flex !important;
    align-items: center !important;
    border-radius: 4px;
    transition: all 0.15s ease-in-out !important;

    user-select: none;

    &-disabled {
      // @include subtypeRadio($color-blue, $color-weak-blue);
      cursor: not-allowed;
    }

    &:hover {
      @include subtypeRadio($color-blue, $color-weak-blue);

      span {
        border-color: $color-blue !important;
      }
    }

    &.ant-radio-wrapper-checked {
      @include subtypeRadio($color-blue, $color-weak-blue);

      &.with-border {
        border: 1px solid $color-weak-blue;
      }
    }

    &.radio {
      &-gray {
        @include subtypeRadio($color-gray, #d9d9d9);

        &.with-border {
          border: 1px solid #d9d9d9;
        }

        &.with-color-border {
          border: 1px solid $color-gray;
        }
      }

      &-dark {
        @include subtypeRadio($color-dark, $color-weak-dark);

        &.with-border {
          border: 1px solid $color-weak-dark;
        }

        &.with-color-border {
          border: 1px solid $color-dark;
        }
      }

      &-blue {
        @include subtypeRadio(#3e9bffec, $color-weak-blue);

        &.with-border {
          border: 1px solid $color-weak-blue;
        }

        &.with-color-border {
          border: 1px solid #3e9bffec;
        }
      }

      &-lightBlue {
        @include subtypeRadio($color-light-blue, #c7e1fd);

        &.with-border {
          border: 1px solid #c7e1fd;
        }

        &.with-color-border {
          border: 1px solid $color-light-blue;
        }
      }

      // &-orange {
      //   @include subtypeRadio(#f89451e7, #ffefd0);

      //   &.with-border {
      //     border: 1px solid #ffefd0;
      //   }

      //   &.with-color-border {
      //     border: 1px solid #f89451e7;
      //   }
      // }

      &-darkBlue {
        @include subtypeRadio($color-dark-blue, #bdcff7);

        &.with-border {
          border: 1px solid #bdcff7;
        }

        &.with-color-border {
          border: 1px solid $color-dark-blue;
        }
      }

      &-red {
        @include subtypeRadio($color-red, $color-weak-red);

        &.with-border {
          border: 1px solid $color-weak-red;
        }

        &.with-color-border {
          border: 1px solid $color-red;
        }
      }

      &-lightRed {
        @include subtypeRadio($color-light-red, $color-weak-light-red);

        &.with-border {
          border: 1px solid $color-weak-light-red;
        }

        &.with-color-border {
          border: 1px solid $color-light-red;
        }
      }

      &-green {
        @include subtypeRadio($color-green, $color-weak-green);

        &.with-border {
          border: 1px solid $color-weak-green;
        }

        &.with-color-border {
          border: 1px solid $color-green;
        }
      }

      &-yellow {
        @include subtypeRadio($color-yellow, $color-weak-yellow);

        &.with-border {
          border: 1px solid $color-weak-yellow;
        }

        &.with-color-border {
          border: 1px solid $color-yellow;
        }
      }

      &-orange {
        @include subtypeRadio($color-orange, $color-weak-orange);

        &.with-border {
          border: 1px solid $color-weak-yellow;
        }

        &.with-color-border {
          border: 1px solid $color-orange;
        }
      }

      &-gold {
        @include subtypeRadio($color-gold, $color-weak-yellow);

        &.with-border {
          border: 1px solid $color-weak-yellow;
        }

        &.with-color-border {
          border: 1px solid $color-gold;
        }
      }

      &-violet {
        @include subtypeRadio($color-violet, $color-weak-violet);

        &.with-border {
          border: 1px solid $color-weak-violet;
        }

        &.with-color-border {
          border: 1px solid $color-violet;
        }
      }
    }
  }
}
