$MAIN_COLOR : #000;

$color-primary: #3e9bffcb;
$color-primary-dark: #096dd9;
$color-innactive: #bfbfbf;
$color-font: #595959;

$color-bg-next-selected: #d9f7be;
$color-bg-next_plus: #feffe6;
$color-bg-next_plus-selected: #ffffb8;
$color-bg-production-selected: #ffcbc7;

$color-weak-blue: #cde4fd;
$color-weak-orange: #fff7e6;
$color-weak-red: #ffccc7;
$color-weak-light-red: #f7d3cf;
$color-weak-green: #ddf3cc;
$color-weak-violet: #e0d1f871;
$color-weak-yellow: #feffe6;
$color-weak-gray: #fafafa;
$color-weak-dark: #b3b3b3;

$color-red: #f5222d;
$color-light-red: #eb777d;
$color-orange: #f89451cb;
$color-gold: #faad14;
$color-yellow: #fadb14;
$color-green: #72b153de;
$color-violet: #9655ff;
$color-blue: #1890ff;
$color-gray: #888888;
$color-dark: #4d4d4d;
$color-light-blue: #51a5ff;
$color-dark-blue: #5676f3;
$color-dark: #4d4d4d;



.entityTagBadge {
  color: #fff !important;
  cursor: pointer;
  border: none;
  pointer-events: none;

  &.textLabel {
    pointer-events: auto;
  }

  &.none {
    color: #545454 !important;
  }

  &.lightGrey {
    background: #a1a1a1 !important;
  }

  &.red {
    background-color: $color-red !important;
  }

  &.tooltipRed {
    .ant-tooltip-inner {
      background-color: $color-red !important;
    }

    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: $color-red !important;
    }
  }

  &.darkRed {
    background-color: #a70000 !important;
  }

  &.orange,
  &.bug-tracker {
    background-color: #fa541c !important;
  }

  &.lightYellow {
    background-color: #ece039 !important;
  }

  &.yellow {
    background-color: $color-gold !important;
  }

  &.green {
    background-color: #4caa1dbb !important;
  }

  &.lightOrange {
    background-color: rgb(250, 139, 20);
  }

  &.skyBlue {
    background-color: #4bc0ff;
  }

  &.lightBlue {
    background-color: #52c1e2 !important;
  }

  &.blue,
  &.feature-tracker {
    background-color: $color-blue !important;
  }

  &.violet {
    background-color: $color-violet !important;
  }

  &.tooltipViolet {
    .ant-tooltip-inner {
      background-color: $color-violet !important;
    }

    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: $color-violet !important;
    }
  }

  &.darkblue,
  &.ticket-tracker {
    background-color: #2f54eb !important;
  }
}

.tag_day_overdue {
  color: #bb302e;
  border: 1px solid #c08b88;
  font-size: 10px;
}

.tag_day_blue {
  color: $color-blue;
  border: 1px solid #4ea9ff;
}

.tag_day_left {
  color: #55a535;
  border: 1px solid #9dce89;
  font-size: 10px;
}

.border-area {
  border: 1px solid rgb(153, 152, 152);
}
