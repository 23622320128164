$color-primary: #3e9bffcb;


.wrapper-switcher-day{
    background-color: white;
    box-shadow: 1px 1px 7px 6px rgba(202, 202, 202, 0.39)!important;
    border: 1px solid #c5c5c58c!important;
    cursor: pointer;
  }

.avatar-wrapper{
    width: 45px;
}
.wrapper-is-online{
    position: absolute;
    bottom: -5px;
    left: 30px;
    border: 4px solid white;
    border-radius: 50%;
    height: 18px;
    width: 18px;

    &.red{
        // background-color: rgb(248, 76, 76);
        background-color: rgb(216, 216, 216);
    }
    &.green{
        background-color: rgb(22, 179, 22);
    }
}
.dayUserReportPlanForToday {
    text-wrap: wrap;
    width: 150px;
    padding: 2px 10px;
    background-color: #e6faff;
  }

  .comment-wrapper {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .primaryCard {
    border-radius: 6px !important;
    box-shadow: -3px 0 0 0 $color-primary, 0px 2px 15px rgba(0, 0, 0, 0.186) !important;
  
    &.background {
      background-color: #d7effaa6;
    }
  
    &.p-0 .ant-card-body {
      padding: 0 !important;
    }
  
    &.p-12 .ant-card-body {
      padding: 12px !important;
    }
  }

  .p-12 {
    padding: 12px !important;
  }

  .universal_header_table_row {
    & th {
      padding: 4px !important;
    }
  
    &.blue > th {
      background-color: #ecf9ff !important;
      color: rgb(100, 100, 100);
    }
  
    &.small > th {
      font-size: 13px;
    }
    &.bordered > th {
      border-right: 1px solid #f0f0f0;
      border-top: 1px solid #f0f0f0;
      &::before {
        width: 0px !important;
        background-color: inherit;
      }
    }
  
    &.color-gray > th{
      color:#636363 !important
    }
  }

.wrapper-switcher-period{
    background-color: white;
    border: 1px solid #dfdfdf8c!important;
    cursor: pointer;
    height: 72px;
  }

  .arrow-wrapper {
    width: 70px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    
    .arrow-wrapper__arrow{
      background-color: #e6faff;
    }
    
    .arrow-wrapper__arrow:hover{
      background-color: #def8ff;
    }
    
    .arrow-wrapper__arrow:hover > .arrow-wrapper__icon{
      color: rgb(24, 144, 255);
    }
    
    .arrow-wrapper__icon{
      color: rgb(45 150 248 / 49%);
    }

    .h5InDateBtn {
        color: inherit !important;
      }
      .hoverDateBtn:hover {
        color: rgb(24, 144, 255) !important;
      }