$color-active: #1890ff;
$color-default: #d9d9d9;
$color-bg: #fafafa;
$color-dark: #4d4d4d;

$color-light-blue: #51a5ff;
$color-dark-blue: #5676f3 ;

@mixin setFilterColor($active, $bg, $border) {
  background-color: $bg;
  border-color: $border;

  input[type='checkbox']:checked {
    background-color: $active;
  }

  &.active {
    color: $active;
    border-color: $active;
  }
}

.areas_filter {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    user-select: none;

    &.isMedium {
      label {
        padding: 2px 6px;
        height: 29px;
        font-size: 15px;
      }

      input[type='checkbox']:checked {
        height: 22px;
        width: 22px;
        margin-right: 4px;
      }

      svg {
        left: 4px;
      }
    }

    &.isSmall {
      label {
        padding: 2px 6px;
        height: 26px;
        font-size: 14px;
      }

      input[type='checkbox']:checked {
        height: 20px;
        width: 20px;
        margin-right: 4px;
      }

      svg {
        left: 4px;
      }
    }

    &.isExtraSmall {
      label {
        padding: 2px 6px;
        height: 23px;
        font-size: 13px;
      }

      input[type='checkbox']:checked {
        height: 18px;
        width: 18px;
        margin-right: 4px;
      }

      svg {
        left: 4px;
      }
    }
  }

  &-container,
  &-group {
    display: flex;

    input[type='checkbox'] {
      width: 0;
      height: 0;
      border-radius: 50%;
      outline: none;
      -webkit-appearance: none;
      cursor: pointer;
      transition: all 0.15s ease-in-out;

      &:checked {
        height: 26px;
        width: 26px;
        background-color: $color-active;
        margin: 0 8px 0 0;
      }
    }

    label {
      position: relative;
      display: flex;
      align-items: center;
      height: 36px;
      border: 1px solid $color-default;
      border-radius: 18px;
      margin: 4px;
      cursor: pointer;
      padding: 2px 12px 2px 6px;
      background-color: $color-bg;

      &.active {
        padding-left: 3px;
        color: $color-active;
        border-color: $color-active;
        svg {
          position: absolute;
          color: #fff;
        }
      }
      label:hover{
        svg {
          color: #1890ff;
        }
      }
    }
  }


  &-group {
    margin: 4px;

    label {
      margin: 0;
      border-radius: 0;

      &:nth-child(1) {
        border-radius: 18px 0 0 18px;
      }

      &:nth-last-child(1) {
        border-radius: 0 18px 18px 0;
      }
    }
  }

  &-excluded {
    display: flex;
    cursor: pointer;
    margin: 4px;

    div {
      display: flex;
      height: 36px;
      align-items: center;
      justify-content: center;
      min-width: 36px;
      transition: all 0.15s ease-in-out;
    }

    div:nth-child(1) {
      border-top: 1px solid $color-default;
      border-left: 1px solid $color-default;
      border-bottom: 1px solid $color-default;
      border-radius: 18px 0 0 18px;
      background-color: $color-bg;
    }

    div:nth-child(2) {
      border: 1px solid $color-default;
      background-color: $color-bg;
    }

    div:nth-child(3) {
      border-top: 1px solid $color-default;
      border-right: 1px solid $color-default;
      border-bottom: 1px solid $color-default;
      border-radius: 0 18px 18px 0;
      background-color: $color-bg;
    }

    &.isSmall {
      height: 26px;

      div {
        padding: 2px 6px;
        min-width: 26px;
        height: 26px;
        font-size: 14px;
      }

      div:nth-child(1) {
        padding: 0;
        border-radius: 13px 0 0 13px;
      }

      div:nth-child(3) {
        padding: 0;
        border-radius: 0 13px 13px 0;
      }
    }

    &.isExtraSmall {
      height: 23px;

      div {
        padding: 2px 6px;
        min-width: 26px;
        height: 23px;
        font-size: 13px;
      }

      div:nth-child(1) {
        padding: 0;
        border-radius: 13px 0 0 13px;
      }

      div:nth-child(3) {
        padding: 0;
        border-radius: 0 13px 13px 0;
      }
    }

    &.included {
      div:nth-child(1) {
        background-color: #72b153de;
        color: #fff;
        border-color: #72b153de;
      }

      div:nth-child(2) {
        color: #67ad43de;
        background-color: #f0fae7;
        border-color: #d3e7c2;
      }
    }

    &.excluded {
      div:nth-child(3) {
        background-color: #f06a71;
        color: #fff;
        border-color: #f06a71;
      }

      div:nth-child(2) {
        border-color: rgb(240, 204, 204);
        background-color: rgba(255, 239, 239, 0.603);
        color: rgba(196, 37, 37, 0.603);
      }
    }
  }
}

.isUsers {
  label {
    @include setFilterColor(#08979c, #e6fffb, #87e8de)
  }
}

label.isArchive {
  @include setFilterColor(#fa8c16, #fff7e6, #ffd591)
}

label.isIdea {
  @include setFilterColor(#faad14 , #fff8cf, #ffd991)
}

label.isDocument {
  @include setFilterColor(#fa541c, #ffefe6, #ffb491)
}

label.isSpreadsheet {
  @include setFilterColor(#4caa1dbb , #f3ffe4, #aceb8e)
}

label.unbanned-tag, label.banned-tag {
  @include setFilterColor(#1cb4fa, #e6f6ff, #91dcff)
}

label.external-tag, label.internal-tag {
  @include setFilterColor(#d46b08, #fff7e6, #ffd591)
}

.switch-is-me{
  font-weight: bold !important;
}

.switch-validation-section{
  color: $color-light-blue !important;
  border: solid 1px rgb(192, 229, 248) !important;
  background-color: #e9f2fcec!important;
  & input{
    background-color: #6eb4ff!important;
  }
}


.switch-development-section{
  color: $color-dark-blue !important;
  border: solid 1px #bfd9fc !important;
  background-color: #d9e5ffec!important;
  & input{
    background-color: #618feb!important;
  }
}

.switch-testing-section, .switch-is-me{
  color: #f89451e7 !important;
  border: solid 1px #ffd7bce7 !important;
  background-color: #fff6f0e7!important;

  & input{
    background-color: #f59e64e7!important;
  }
}

.switch-deploy-section{
  color: #72b153de !important;
  border: solid 1px #c0dfb2de !important;
  background-color: #f1faecde!important;

  & input{
    background-color: #85af70de!important;
  }
}

.switch-purgatory-section{
  color:#888888 !important;
  border: solid 1px #d4d4d4 !important;
  background-color: #f3f3f3!important;

  & input{
    background-color: #a3a3a3!important;
  }
}

.switch-end-section{
  color:$color-dark!important;
  border: solid 1px #afafaf !important;
  background-color: #e4e4e4!important;

  & input{
    background-color: #5f5f5f!important;
  }
}

.switch-tracker-bug{
  color: #fa7143 !important;
  border: 1px solid #f7bba8!important;
  background-color: #fde7df!important;

  & input{
    background-color: #ff8056!important;
  }
}

.wrapper-text-tag {
  // font-size: 12px;
  letter-spacing: -0.03em;
  font-style: normal;
}