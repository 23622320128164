@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --color-text: #272a2e;
    --color-primary-dark: #0195e4;
    --color-primary-light: #9ddcff;
    --color-secondary: #4be35a;
    --color-tertiary: #f7f7f7;
    --color-orange: #ef5350;
    --color-outline: #e9eaeb;
    --border-radius: 4px;
    --cell-height: 38px;
}