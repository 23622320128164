.blue-base-card {
  .ant-card-head {
    padding: 16px 24px;
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
  }

  .ant-card-body {
    padding: 0;
  }
}

.groups-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  
  .ant-tag {
    cursor: pointer;
    user-select: none;
    padding: 4px 8px;
    
    &.selected {
      background-color: #1890ff;
      color: white;
      border-color: #1890ff;
    }
    
    &:hover {
      opacity: 0.8;
    }
  }
}

.form-actions {
  margin-top: 24px;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
} 