.events-list::-webkit-scrollbar {
  width: 6px;
}

.events-list::-webkit-scrollbar-track {
  background: transparent;
}

.events-list::-webkit-scrollbar-thumb {
  background-color: #e2e8f0;
  border-radius: 3px;
}

.events-list::-webkit-scrollbar-thumb:hover {
  background-color: #cbd5e1;
}

.trigger-section-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 1000px;
}

.trigger-section-content.collapsed {
  max-height: 0;
  padding: 0;
  margin: 0;
}

.event-card.selected-card {
  background: #f8faff !important;
  z-index: 2;
  box-shadow: 
    inset 3px 0 0 #4299e1,
    0 4px 12px rgba(66, 153, 225, 0.1) !important;
}

.event-card-icon::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  background: inherit;
  filter: blur(8px);
  opacity: 0.4;
  z-index: -1;
}

.trigger-section-header .collapse-button.collapsed .anticon {
  transform: rotate(-90deg);
}
