.monthControll {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: 0.5s all ease;
}

.monthControll:hover {
    background-color: #e6faff;
}

.arrow-wrapper__arrow:hover>.arrow-wrapper__icon {
    color: rgb(24, 144, 255);
}

.arrow-wrapper__icon {
    color: rgb(45 150 248 / 49%);
}