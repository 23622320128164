.button-open-start-work-record {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    margin-right: 15px;
}

.color-update-record {
    color: rgb(247, 123, 77);
}

.color-create-record {
    color: rgb(124, 163, 62);
}

@media (max-width: 950px) {
    .button-open-start-work-record {
        display: none;
    }
}