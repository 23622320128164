.logo {
  display: flex;
  align-items: center;

  &-icon {
    width: 28px;
  }

  &-text {
    font-size: 20px;
    font-weight: 500;

    > span {
      &:first-child {
        color: #222;
      }

      &:last-child {
        background: -webkit-linear-gradient(#52ABD9, #000F70);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
