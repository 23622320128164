.user-item-for-list {
    cursor: pointer;
    height: 50px;
    border-top: 1px solid #f0f0f0;
    width: 100%;
    padding-left: 10px;

    &:hover {
      background-color: #F5F5F5;
    }

    &-selected {
      cursor: pointer;
      height: 50px;
      border-top: 1px solid #f0f0f0;
      background-color: #e6f4ff;
      width: 100%;
      padding-left: 10px;
    }
}

.user-item-tooltip {
  width: fit-content;
  text-wrap: nowrap;
}