.user-list-wrapper {
    width: 450px;
    height: 420px;
    overflow: hidden;
    border-right: 1px solid #DCDCDC;
    // border-radius: 10px;
    padding-left: 10px;
    padding-bottom: 70px;
}

.approved-background {
    background-color: #87d06833;
}

.rejected-background {
    background-color: #ff550033;
}

.upcoming-background {
    background-color: #e6f4ff;
}

.history-header {
    font-size: 20px,
}

.history-header-number {
    margin-left: 4;
    margin-right: 4;
}

.history-container-dashed {
    border: 1px dashed #c7c5c5;
    border-radius: 2px;
}
