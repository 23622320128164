$MAIN_COLOR : #000;

$color-primary: #3e9bffcb;
$color-primary-dark: #096dd9;
$color-innactive: #bfbfbf;
$color-font: #595959;

$color-bg-next-selected: #d9f7be;
$color-bg-next_plus: #feffe6;
$color-bg-next_plus-selected: #ffffb8;
$color-bg-production-selected: #ffcbc7;

$color-weak-blue: #cde4fd;
$color-weak-orange: #fff7e6;
$color-weak-red: #ffccc7;
$color-weak-light-red: #f7d3cf;
$color-weak-green: #ddf3cc;
$color-weak-violet: #e0d1f871;
$color-weak-yellow: #feffe6;
$color-weak-gray: #fafafa;
$color-weak-dark: #b3b3b3;

$color-red: #f5222d;
$color-light-red: #eb777d;
$color-orange: #f89451cb;
$color-gold: #faad14;
$color-yellow: #fadb14;
$color-green: #72b153de;
$color-violet: #9655ff;
$color-blue: #1890ff;
$color-gray: #888888;
$color-dark: #4d4d4d;
$color-light-blue: #51a5ff;
$color-dark-blue: #5676f3;
$color-dark: #4d4d4d;
