.wrapper_card_calendar {
    padding: 0px 10px;
    background-color: white;
}

.wrapper_day_calendar {
    min-height: 80px;
    height: 100%;
    padding: 14px 10px 10px;
    margin: 5px;
    position: relative;

    border: 1px solid rgb(223, 223, 223);

    background-color: rgb(247, 252, 255);
    color: rgb(32, 93, 128);

    &.isDayoff {
        background-color: rgb(255, 245, 241);
        color: rgb(128, 55, 33);
    }

    &.isBench {
        background-color: #FFE8CC;
        color: #FF8C00;
    }

    &.isReportNotSent {
        background-color: rgb(243, 243, 243);
        color: rgb(99, 99, 99);
    }

    &.isWeekendWork {
        border: 1px solid rgb(223, 223, 223);
        background-color: rgb(247, 252, 255);
        color: rgb(32, 93, 128);
    }

    &.customWorkTime {
        border: 1px solid rgb(241, 215, 156);
        background-color: rgb(250, 239, 218);
        color: rgb(175, 123, 26);
    }

    &.nameDayIsDayOff {
        background-color: rgb(243, 243, 243);
        color: rgb(99, 99, 99);

    }

    &.isVacation {
        border: 1px solid rgb(176, 241, 156);
        background-color: rgb(236, 250, 218);
        color: rgb(86, 175, 26);
    }

    &.isToDay {
        background-color: rgb(222, 241, 252);
        border: 1px solid rgb(74, 170, 226);
        color: rgb(41, 177, 255);
    }

    &.active {
        background-color: rgb(241, 250, 255);
        border: 1px solid rgb(136, 207, 248);
        color: rgb(41, 177, 255);
    }

    &.oldDay{
        color: rgba(145, 145, 145, 0.363);
        border: 1px solid rgb(238, 238, 238);
    }
}

.day_calendar {
    position: absolute;
    top: -3px;
    left: 3px;
}
