.events-header {
  background: white;
}

.events-header .ant-card-body {
  padding: 24px;
}

.filters-row {
  margin-top: 16px !important;
}

.filter-item {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 200px;
}

.filter-icon {
  font-size: 18px;
  flex-shrink: 0;
}

.filter-select {
  flex: 1;
}

.search-input .ant-input {
  height: 40px;
  border-radius: 8px;
}

.search-input .ant-input-search-button {
  height: 40px;
  border-radius: 0 8px 8px 0;
}

.ant-btn-primary-active {
  background: #1677ff;
}

/* Анимация для появления фильтров */
.header-filters {
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 1200px) {
  .filters-row .ant-space {
    flex-direction: column;
    width: 100%;
  }

  .filter-item {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .events-header .ant-card-body {
    padding: 16px;
  }
  
  .filter-item {
    margin-bottom: 8px;
  }
} 