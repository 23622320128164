@mixin card-shadows {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

// animations
@mixin smooth-fade-in-up($delay, $duration, $ease) {
  opacity: 0;
  animation-name: smoothFadeInUp;
  animation-timing-function: $ease;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: both;

  @keyframes smoothFadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 3%, 0);
    }

    90% {
      opacity: 0.3;
      transform: none;
    }

    95% {
      opacity: 0.5;
      transform: none;
    }

    100% {
      opacity: 1;
      transform: none;
    }
  }
}

@mixin setTagColor($tag-color, $tag-color-bg) {
  color: $tag-color;
  background: $tag-color-bg;

  &._hovered:hover {
    background: darken($tag-color-bg, 8%);
  }

  & .chevron {
    transition: all 0.25s;

    &._open {
      transform: rotate(-180deg);
    }
  }
}

@mixin selectedVector($color-bg, $color-border) {
  box-shadow: 3px 0 0 0 $color-border inset !important;
  background-color: $color-bg;
}

@mixin getCardBoarder($color) {
  box-shadow: -3px 0 0 0 $color, 0px 2px 15px rgba(0, 0, 0, 0.05) !important;
}

@mixin subtypeRadio($color, $color-selected) {
  color: $color !important;
  // border: solid 1px $color !important;

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba($color, 0.1) !important;
  }

  .ant-radio-inner {
    border-color: $color !important;

    &::after {
      background-color: $color !important;
      width: 20px !important;
      height: 20px !important;
      margin-top: -10px !important;
      margin-left: -10px !important;
    }
  }

  span.ant-radio {
    top: 0 !important;
  }

  .ant-radio-checked::after {
    border-color: $color !important;
  }

  input[type="radio"]:focus {
    outline-color: $color !important;
  }

  &.ant-radio-wrapper-checked,
  &:hover {
    background: $color-selected !important;
  }
  // --------------
  &.ant-radio-wrapper-checked {
    &.ant-radio-wrapper-disabled {
      border-color: $color !important;

      &:hover {
        background: $color-selected !important;
      }

      .ant-radio-disabled + span {
        color: $color;
      }
    }
  }

  &.ant-radio-wrapper-disabled {
    border-color: rgba(0, 0, 0, 0.25) !important;

    &:hover {
      background: none !important;
    }
  }
  // --------------
}

@mixin disabledButtonColor {
  &[disabled],
  &[disabled]:hover {
    color: $color-innactive !important;
    border-color: $color-innactive !important;
    background: #f5f5f5 !important;
  }
}

@mixin getBtnColor($btn-color, $btn-color-hover) {
  color: #fff !important;
  background-color: $btn-color !important;
  border-color: transparent !important;
  line-height: inherit;

  &:hover {
    background-color: $btn-color-hover !important;
  }

  @include disabledButtonColor;
}

@mixin getNotClickableBtnColor($btn-color, $btn-color-hover) {
  color: $btn-color !important;
  background-color: transparent !important;
  cursor: text !important;
  border-color: $btn-color !important;
  line-height: inherit;

  @include disabledButtonColor;
}

@mixin getNotClickableBtnOutlineColor($btn-color, $btn-color-hover) {
  color: $btn-color !important;
  border-color: $btn-color !important;

  @include disabledButtonColor;
}

@mixin getBtnOutlineColor($btn-color, $btn-color-hover) {
  color: $btn-color !important;
  border-color: $btn-color !important;

  &:hover {
    color: #fff !important;
    background-color: $btn-color-hover !important;
    border-color: $btn-color-hover !important;
  }

  @include disabledButtonColor;
}

@mixin getBtnSelectColor($btn-color, $btn-color-hover, $btn-color-onHover) {
  color: #fff !important;
  border-color: $btn-color-hover !important;
  background-color: $btn-color-hover !important;

  &:hover {
    color: white !important;
    border-color: $btn-color-onHover !important;
    background-color: $btn-color-onHover !important;
  }

  @include disabledButtonColor;
}

@mixin getBtnLinkColor($btn-color, $btn-color-hover) {
  background: none !important;
  border: none !important;
  color: $btn-color !important;
  box-shadow: none !important;
  padding: 0 !important;
  outline: none !important;
  animation-duration: 0s !important;
  transition: all 0.15s ease-in-out !important;

  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  &:hover {
    color: $btn-color-hover !important;
  }
}

@mixin getBtnCircleColor($btn-color, $btn-color-hover) {
  color: $btn-color !important;
  animation-duration: 0s !important;
  transition: all 0.15s ease-in-out !important;
  outline: none !important;
  border: none !important;
  background-color: rgb(252, 249, 244) !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  padding: 0px;

  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  &:hover {
    color: $btn-color-hover !important;
  }
}

@mixin getBackColor($color, $color-bg, $color-border) {
  color: $color;
  border-radius: 6px;
  border: 1px solid $color-border;
  background-color: $color-bg;
}

@mixin typeCollapse($color, $color-bg) {
  box-shadow: 0 3px 0 0 $color, 0 2px 15px rgba(0, 0, 0, 0.05) !important;

  .collapseComponent-button_container {
    background-color: $color-bg;

    .collapsedText {
      color: $color;
    }
  }
}

$MAIN_COLOR : #000;

$color-primary: #3e9bffcb;
$color-primary-dark: #096dd9;
$color-innactive: #bfbfbf;
$color-font: #595959;

$color-bg-next-selected: #d9f7be;
$color-bg-next_plus: #feffe6;
$color-bg-next_plus-selected: #ffffb8;
$color-bg-production-selected: #ffcbc7;

$color-weak-blue: #cde4fd;
$color-weak-orange: #fff7e6;
$color-weak-red: #ffccc7;
$color-weak-light-red: #f7d3cf;
$color-weak-green: #ddf3cc;
$color-weak-violet: #e0d1f871;
$color-weak-yellow: #feffe6;
$color-weak-gray: #fafafa;
$color-weak-dark: #b3b3b3;

$color-red: #f5222d;
$color-light-red: #eb777d;
$color-orange: #f89451cb;
$color-gold: #faad14;
$color-yellow: #fadb14;
$color-green: #72b153de;
$color-violet: #9655ff;
$color-blue: #1890ff;
$color-gray: #888888;
$color-dark: #4d4d4d;
$color-light-blue: #51a5ff;
$color-dark-blue: #5676f3;
$color-dark: #4d4d4d;


.entityTag {
  display: flex;
  align-items: center;
  line-height: inherit;

  @include setTagColor(#8c8c8c, #f0f0f0);

  &.with-border {
    border: 1px solid #b1b1b1;
    height: fit-content;
    width: fit-content;
  }

  &.bug-tracker {
    @include setTagColor(#ffffff, #fa531cd7);
  }

  &.feature-tracker {
    @include setTagColor(#ffffff, #188fffda);
  }

  &.ticket-tracker {
    @include setTagColor(#ffffff, #5976e7e0);
  }

  // &.system {
  //   @include setTagColor(#2e54eeea,#eaebff);
  //   &.with-border{ border: 1px solid #bebdfae0 !important;}
  // }

  &.red,
  &.high,
  &.difficult,
  &.production,
  &.urgent,
  &.significant,
  &.important {
    @include setTagColor($color-red, $color-weak-red);

    &.with-border {
      border: 1px solid #ffaaa4 !important;
    }
  }

  &.created,
  &.acquainted,
  &.reopened,
  &.rejected,
  &.accepted,
  &.minor {
    @include setTagColor($color-light-blue, #d3f1ffa6);

    &.with-border {
      border: 1px solid #c0e5f8 !important;
    }
  }

  &.progress,
  &.review,
  &.bugs,
  &.ready.for.deploy,
  &.fixing {
    @include setTagColor($color-dark-blue, #cbdaf7a6);

    &.with-border {
      border: 1px solid #b3ccfa !important;
    }
  }

  &.discussion,
  &.low,
  &.planning {
    // @include setTagColor(#2f54eb, #d6e4ff);
    @include setTagColor(#3e9bffec, #e4ecfd);

    &.with-border {
      border: 1px solid #7dbbfdec !important;
    }
  }

  // &.paused,
  // &.backlog,
  &.yellow,
  &.archive {
    @include setTagColor($color-gold, #fff4ca);

    &.with-border {
      border: 1px solid #ffeeac !important;
    }
  }


  &.very_complicated,
  &.critical {
    @include setTagColor(#722ed1, #e4d8f8);

    &.with-border {
      border: 1px solid #d1afff !important;
    }
  }

  &.orange,
  &.normal {
    @include setTagColor(#fa541c, #ffd8bf);

    &.with-border {
      border: 1px solid #ffaf95 !important;
    }
  }

  &.test.failed,
  &.not.reproducible,
  &.test,
  &.testing,
  &.medium {
    @include setTagColor(#f89451e7, #fff6f0e7);

    &.with-border {
      border: 1px solid #ffd7bce7 !important;
    }
  }

  &.protected {
    @include setTagColor(#f89451, #ffe2cee7);

    &.with-border {
      border: 1px solid #ffc6a0e7 !important;
    }
  }

  &.active,
  &.resolved,
  &.deploy {
    @include setTagColor($color-green, #f0fae7);

    &.with-border {
      border: 1px solid #90ca72 !important;
    }
  }

  &.green {
    @include setTagColor(#4caa1dbb, #efffe1);

    &.with-border {
      border: 1px solid #90ca72 !important;
    }
  }

  &.darkRed {
    @include setTagColor(#a70000, #f0fae7);

    &.with-border {
      border: 1px solid #a85353 !important;
    }
  }

  &.closed,
  &.abandoned,
  &.deployed {
    @include setTagColor($color-dark, #d6d6d6);

    &.with-border {
      border: 1px solid #9b9b9b !important;
    }
  }

  &.backlog,
  &.blocked,
  &.paused,
  &.outdated,
  &.draft,
  &.system {
    @include setTagColor(#8c8c8c, #f0f0f0);

    &.with-border {
      border: 1px solid #adadad !important;
    }
  }
}

.default_style_icon_table {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.default_style_icon_table_no_cut {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 1px 7px 1px 7px;
  border-radius: 4px;
}
