$color-highlighted: #1677ff;
$pagination-item-height: 28px;
$pagination-item-width: 28px;
$pagination-margin: 2.5%;

.pagination {
  margin: 20px 0 6px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;

  .shown-items {
    margin-right: $pagination-margin;
    padding: 8px;
    height: $pagination-item-height;
    display: flex;
    align-items: center;
    color: $color-highlighted;
    border-radius: 4px;
    background-color: #e6f4ff;
  }

  &__list {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
    list-style: none;

    &-item {
      margin: 0;
      padding-inline: 8px;
      min-width: $pagination-item-width;

      text-wrap: nowrap;
      border-radius: 4px;

      button {
        padding: 0 6px;
      }

      &:hover {
        color: $color-highlighted;
      }

      &:first-child,
      &:last-child {
        padding-inline: 0;
      }

      &.--active {
        font-weight: 500;
        color: #ffffff;
        background: $color-highlighted;
        box-shadow: inset 0 0 0 1px $color-highlighted, 0 1px 0px 0px #e0e0e0, 0 2px 4px 0px rgba(0, 0, 0, 0.3);
        // transition: background ease-out 0.1s, color ease-out 0.1s, box-shadow ease-out 0.1s;
      }
    }
  }
}

.pagination-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $pagination-item-height;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-align: center;
  line-height: 1;
  cursor: pointer;

  button {
    height: 100%;
  }
}

.pageLimit {
  margin-left: $pagination-margin;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;

  &__title {
    flex-shrink: 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  &__item {
    padding-inline: 10px;
    color: $color-highlighted;
    background: #ffffff;
    border-radius: 4px;
    border: none;
    outline: none;
    box-shadow: inset 0 0 0 1px $color-highlighted;
    transition: background ease-out 0.15s, color ease-out 0.15s;

    &.--active {
      box-shadow: inset 0 0 0 1px $color-highlighted, 0px 1px 0px 0px #e0e0e0, 0 2px 4px 0px rgba(0, 0, 0, 0.3);
    }

    &:hover {
      color: #ffffff;
      background: $color-highlighted;
    }

    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}

.dots{
    font-size: large;
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;

    &:hover {
      color: $color-highlighted;
    }
}

.svg-arrow {
  background: transparent;
  border-color: transparent;

  &:hover svg {
    fill: $color-highlighted;
  }

  &.--disabled {
    &:hover {
      cursor: not-allowed;
    }

    svg {
      fill: rgba(0, 0, 0, 0.25);
    }
  }
}
