.btnDanger {
    color: #cc161f !important;
    background-color: #fff !important;
    border: 1px solid #cc161f;
    line-height: inherit;
  
    &:hover {
      color: #fff !important;
      border: 1px solid #cc161f !important;
      background-color: #cc161f !important;
    }

    &-outline {
      color: #cc161f !important;
      border-color: #cc161f !important;
    
      &:hover {
        color: #fff !important;
        background-color: #ac0b18 !important;
        border-color: #ac0b18 !important;
      }
    }
  
    &-selected {
      color: #fff !important;
      border-color: #ac0b18 !important;
      background-color: #ac0b18 !important;
    
      &:hover {
        color: white !important;
        border-color: #ac0b18 !important;
        background-color: #ac0b18 !important;
      }
    }
  }

  .btnGreen {
    color: #fff !important;
    background-color: #407724de !important;
    border-color: transparent !important;
    line-height: inherit;
  }