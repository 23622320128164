/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

button {
  background-color: inherit;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 14px
}

::-webkit-scrollbar-track {
  background-color: #fff
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;

  &:hover {
    border: 3px solid #fff;
  }
}

html,
body {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

.ant-modal,
[class^="ant-notification"],
[class*=" ant-notification"],
input,
span,
.ant-typography {
  font-family: "Ubuntu", sans-serif;
}

.ant-table-wrapper .ant-table-container table>thead .ant-table-cell {
  font-weight: 500;
}

.ant-card .ant-card-head {
  font-weight: 500;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0;
}

.ant-form-item .ant-form-item-label>label {
  font-size: 12px;
  color: #777;
}

.ant-form-item .ant-form-item-explain,
.ant-form-item .ant-form-item-extra {
  font-size: 12px;
  margin-bottom: 6px;
}

.ant-card .ant-card-head {
  padding: 0 16px;
}

.ant-card .ant-card-body {
  padding: 16px;
}

.ant-form-item .ant-form-item-label {
  text-align: start;
}

.ant-segmented .ant-segmented-item {
  border: 1px solid transparent;
}

.ant-segmented .ant-segmented-item-selected {
  border: 1px solid #1677ff;
  background: #E6F4FF;
}

.ant-card .ant-card-actions>li {
  margin: 6px 0;
}

/* TABLES */
.table-selectable tr {
  cursor: pointer;
}

.timeline-statuses-table .col-project-name {
  width: 20%;
}

.timeline-statuses-table .col-status {
  width: 20%;
}

.timeline-statuses-table .col-date {
  width: 15%;
}

.ant-result .ant-result-subtitle {
  font-size: 16px;
  font-weight: 400;
}

.react-datepicker {
  font-family: "Ubuntu", sans-serif !important;
  border-color: #d9d9d9 !important;
}

.react-datepicker__header {
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: rgba(207, 207, 207, 0.4) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #1677ff !important;
}

.spin-h-full {
  height: 100%;

  &>div {
    height: inherit;
  }
}

// Avatar
.avatar {

  &-my,
  &-user {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 700;
    font-size: 16px;

    &.small {
      font-size: 12px;
    }

    p {
      margin: 0;
    }
  }
}

.logo-wrapper {
  -webkit-box-shadow: -24px -26px 11px -24px rgba(202, 202, 202, 0.21) inset;
  -moz-box-shadow: -24px -26px 11px -24px rgba(202, 202, 202, 0.21) inset;
  box-shadow: -24px -26px 11px -24px rgba(202, 202, 202, 0.21) inset;
}

.card-h-full {
  height: calc(100% - 40px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  & .ant-card-body {
    height: inherit;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }
}

.more-reports:hover {
  color: rgba(62, 155, 255, 0.925);
}

.view_btn_link__orange {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 248, 239, 0.603) !important;
  color: rgba(240, 148, 29, 0.603) !important;
  border: 1px solid rgba(240, 148, 29, 0.603) !important;

  &:hover,
  &:focus {
    color: rgba(226, 128, 0, 0.603) !important;
    border: 1px solid rgba(253, 200, 131, 0.603) !important;
    background-color: rgba(255, 236, 212, 0.603) !important;
  }

  &.border, .border:focus {
    border: 1px solid rgb(226, 202, 149) !important;
  }
}

.ant-uploader-for-table .ant-upload-select {
  display: none;
}
.ant-uploader-for-table .ant-upload-list-item-container {
  width: 50px;
  height: 50px;
}

.geekblue-tag {
  transition: all 0.2s ease-out;
  border: 1px dashed rgb(29, 57, 196);
}

.geekblue-tag:hover {
  background-color: rgb(29, 57, 196);
  color: white;
}

.day-off-button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
}

.sickday-button {
  color: #803721;
  background-color: #fff5f1;
  border: 1px solid #803721;
  border-radius: 5px; 

  &-selected {
    color: white;
    background-color: #803721;
    border-radius: 5px; 
  }
  &:hover {
    color: white;
    background-color: #803721;
  }
}

.dayoff-button {
  color: #ff0000;
  background-color: #FFE5E5;
  border: 1px solid #ff0000;
  border-radius: 5px; 
  &-selected {
    color: #FFE5E5;
    background-color: #ff0000;
    border-radius: 5px; 
  }
  &:hover {
    background-color: #ff0000;
    color: white;
  }
}

.holidays-button {
  color: #803721;
  background-color: #fff5f1;
  border: 1px solid #803721;
  border-radius: 5px; 

  &-selected {
    color: white;
    background-color: #803721;
    border-radius: 5px; 
  }
  &:hover {
    color: white;
    background-color: #803721;
  }
}

.work-button {
  color: #038fde;
  background-color: #E4F3FA;
  border: 1px solid #038fde;
  border-radius: 5px; 
  &:hover {
    color: white;
    background-color: #038fde;
  }
}

.bench-button {
  color: #FF8C00;
  background-color: #FFF3E5;
  border: 1px solid #FF8C00;
  border-radius: 5px; 
  width: 34px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;

  &-selected { 
    color: white;
    width: 34px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF8C00;
    border-radius: 5px; 
    transition: all 0.2s ease-out;
  
  }

  &:hover {
    color: white;
    background-color: #FF8C00;
  }
}
