$lightBlueWhenHover: rgba(238, 250, 255, 0.593);
$lightBlueSelectedRow: #e6faff;
$color-primary: #3e9bffcb;
$color-primary-dark: #096dd9;
$color-innactive: #bfbfbf;
$color-font: #595959;

$color-bg-next-selected: #d9f7be;
$color-bg-next_plus: #feffe6;
$color-bg-next_plus-selected: #ffffb8;
$color-bg-production-selected: #ffcbc7;

$color-weak-blue: #cde4fd;
$color-weak-orange: #fff7e6;
$color-weak-red: #ffccc7;
$color-weak-green: rgb(223, 243, 204);
$color-weak-yellow: #feffe6;
$color-weak-gray: #fafafa;

$color-red-border-row: rgb(252, 208, 208);
$color-red: #f5222d;
$color-orange: #f89451cb;
$color-gold: #faad14;
$color-yellow: #fadb14;
$color-green: #72b153de;
$color-blue: #1890ff;
$color-gray: #888888;

$color-border-row: #888888;

@mixin selectedVector($color-bg, $color-border) {
  & td:first-child {
    box-shadow: 3px 0 0 0 $color-border inset !important;
  }
  & td {
    background-color: $color-bg;
  }
}

.universal_header_table_row {
  & th {
    padding: 4px !important;
  }

  &.blue > th {
    background-color: #ecf9ff !important;
    color: rgb(100, 100, 100);
  }

  &.small > th {
    font-size: 13px;
  }
  &.bordered > th {
    border-right: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
    &::before {
      width: 0px !important;
      background-color: inherit;
    }
  }

  &.color-gray > th{
    color:#636363 !important
  }
}

.universal_body_table_row {
  &.small > td {
    padding: 4px !important;
  }
}

.row_viewed_notifications {
  background-color: $color-weak-gray;

  & > * {
    color: rgb(160, 160, 160);
  }
}

tr.expandedRowDailyReport > td {
  background-color: rgba(204, 236, 248, 0.603) !important;
}

.universal_table_row {
  & td {
    padding: 6px !important;
  }

  &:hover {
    & td {
      background-color: $lightBlueWhenHover !important;
    }
  }

  &.cantBeChanged {
    background-color: lighten(#ffcf6e, 8%);
  }

  &.blue {
    background-color: #def2ff;
  }

  &.selected {
    .light-dark {
      div {
        color: #00b3ff !important;
      }
    }

    background-color: #afe7ff;
  }

  &.border {
    box-shadow: 0px 0px 1px 1px inset rgb(151, 151, 151) !important;
    & td {
      background-color: inherit !important;
      border-bottom: none;
    }
  }

  &.light-green.ant-table-row.ant-table-row-selected > td {
    background-color: #f1ffe6 !important;
  }
}

.universal_table_row:hover {
  cursor: pointer;
}

.red_table_row {
  background-color: #fcebe85d !important;
}

.select_table_row {
  cursor: pointer !important;

  &.selected {
    box-shadow: 0px 0px 1px 1px inset rgb(151, 151, 151) !important;

    & td {
      background-color: inherit !important;
      border-bottom: none;
    }
  }
}

.cell-green {
  background-color: #def1ca !important;
}

.cell-grey {
  background-color: #f5f5f5;
}

.cell-warning {
  background-color: #fff8ed !important;
}

// table filter
.ant-dropdown .ant-table-filter-dropdown {
  min-width: unquote("min(300px, 20vw)");
}

.ant-table-wrapper .ant-table-filter-trigger {
  margin-inline-end: 0;

  &.active {
    background-color: rgba(169, 198, 231, 0.4392156863);
  }
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger {
  margin-inline-end: 0;
}

.select_table_row {
  &.selected {
    td {
      border-bottom: none !important;
    }
  }
}

.ant-table-selection-column {
  display: none;
}

.not-hide-checkbox .ant-table-selection-column {
  display: table-cell !important;
}

.header_table_blue > th {
  color: rgb(24, 144, 255) !important;
  background-color: #e6faff;
  cursor: pointer;
  padding: 6px;

  &:hover {
    background-color: #e6f4ff !important;
  }

  &.expand-cell-header {
    padding: 0px;
    height: 100%;
    & .parent_cell_expand_row_table {
      width: 100%;
      padding: 6px;
      color: rgba(179, 179, 179, 0.315) !important;
    }
  }
}
