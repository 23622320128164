$MAIN_COLOR : #000;

$color-primary: #3e9bffcb;
$color-primary-dark: #096dd9;
$color-innactive: #bfbfbf;
$color-font: #595959;

$color-bg-next-selected: #d9f7be;
$color-bg-next_plus: #feffe6;
$color-bg-next_plus-selected: #ffffb8;
$color-bg-production-selected: #ffcbc7;

$color-weak-blue: #cde4fd;
$color-weak-orange: #fff7e6;
$color-weak-red: #ffccc7;
$color-weak-light-red: #f7d3cf;
$color-weak-green: #ddf3cc;
$color-weak-violet: #e0d1f871;
$color-weak-yellow: #feffe6;
$color-weak-gray: #fafafa;
$color-weak-dark: #b3b3b3;

$color-red: #f5222d;
$color-light-red: #eb777d;
$color-orange: #f89451cb;
$color-gold: #faad14;
$color-yellow: #fadb14;
$color-green: #72b153de;
$color-violet: #9655ff;
$color-blue: #1890ff;
$color-gray: #888888;
$color-dark: #4d4d4d;
$color-light-blue: #51a5ff;
$color-dark-blue: #5676f3;
$color-dark: #4d4d4d;

.tab-container {
  flex: 1 1 auto;

  .header-buttons-section-comment {
    justify-content: center;
  }
}

.disabledTab {
  color: #00000040 !important;
  background-color: transparent !important;
  cursor: not-allowed !important;
}

.general_item {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  padding: 12px 20px;
  background-color: $color-weak-gray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: transparent;
  border: 1px solid rgb(228, 228, 228);

  &.active {
    border-bottom: 2px solid #038fde;
    color: #038fde;
    background-color: #fff;
  }
}

.custom_reportTab {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  padding: 6px 10px;
  background-color: $color-weak-gray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: transparent;
  border: 1px solid rgb(228, 228, 228);

  &.active {
    border-bottom: 2px solid #038fde;
    color: #038fde;
    background-color: #fff;
  }
}

.custom_userReport_detail {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  padding: 3px 5px;
  background-color: $color-weak-gray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: transparent;
  border: 1px solid rgb(228, 228, 228);

  &.active {
    border-bottom: 2px solid #038fde;
    color: #038fde;
    background-color: #fff;
  }
}

.info_card {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 3px 5px;
  background-color: #f5f5f568;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #f5f5f58a;
  border-bottom: 2px solid #f5f5f568;

  &.active {
    border: 1px solid #f5f5f58a;
    border-bottom: 2px solid #038fde;
    color: #038fde;
    background-color: #fff;
  }
}

.header-buttons-section-comment {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 3px 5px;
  background-color: #eeeeee68;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #e0e0e08a;
  border-bottom: 2px solid #e0e0e08a;

  &.active {
    border: 1px solid #bce3fa;
    border-bottom: 2px solid #038fde;
    color: #038fde;
    background-color: #e1f2fc;
  }

  &:hover {
    background-color: #d1d1d168;
  }
}

.report_card {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 3px 5px;
  background-color: #f5f5f568;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #acacac8a;
  border-bottom: 1px solid #acacac8a;

  &.active {
    border: 1px solid #c3dff0;
    border-bottom: 2px solid #038fde;
    color: #038fde;
    background-color: #f5fcff;
  }
}

.subData-tag {
  position: absolute;
  top: -8px;
  right: 0;
  z-index: 10;
}
