$MAIN_COLOR : #000;

$color-primary: #3e9bffcb;
$color-primary-dark: #096dd9;
$color-innactive: #bfbfbf;
$color-font: #595959;

$color-bg-next-selected: #d9f7be;
$color-bg-next_plus: #feffe6;
$color-bg-next_plus-selected: #ffffb8;
$color-bg-production-selected: #ffcbc7;

$color-weak-blue: #cde4fd;
$color-weak-orange: #fff7e6;
$color-weak-red: #ffccc7;
$color-weak-light-red: #f7d3cf;
$color-weak-green: #ddf3cc;
$color-weak-violet: #e0d1f871;
$color-weak-yellow: #feffe6;
$color-weak-gray: #fafafa;
$color-weak-dark: #b3b3b3;

$color-red: #f5222d;
$color-light-red: #eb777d;
$color-orange: #f89451cb;
$color-gold: #faad14;
$color-yellow: #fadb14;
$color-green: #72b153de;
$color-violet: #9655ff;
$color-blue: #1890ff;
$color-gray: #888888;
$color-dark: #4d4d4d;
$color-light-blue: #51a5ff;
$color-dark-blue: #5676f3;
$color-dark: #4d4d4d;


$color-green: #849449cb;

.gray-text {
  color: rgb(94, 94, 94);
}

.color-blue .ant-badge-count {
  background-color: $color-primary !important;
}

.row-table-in-permissions {
  &>* {
    cursor: pointer;
    padding: 6px !important;
  }

  & .expand-cell {
    background-color: #f2f5f860;

    .expand_wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.header_table_title {
  width: 100%;
  display: flex;
  background-color: #cbdfeeb6;
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
  font-size: 18px;
  color: rgb(85, 85, 85);

  &.orange_color {
    background-color: rgba(250, 140, 22, 0.213) !important;
    // background-color: #a8b7c2b6!important;
  }
}


.header-table-perm>th {
  color: rgb(80, 80, 80) !important;
  background-color: rgba(179, 179, 179, 0.315);
  cursor: pointer;
  padding: 6px;

  &.expand-cell-header {
    padding: 0px;
    display: flex;
    height: 100%;
    align-items: stretch;

    & .parentCellExpandRow {
      width: 100%;
      padding: 6px;
      color: rgba(179, 179, 179, 0.315) !important;
    }
  }
}


.row-perm-expand-table {
  background-color: #fdfcfc51;
}

.row-perm {
  background-color: #f2f5f860;
}

.row-perm-selected {
  background-color: $color-weak-blue !important;
}

.row-perm-delete {
  cursor: pointer;
  color: rgb(182, 83, 83);

  &:hover {
    color: rgb(138, 61, 61);
  }
}


.circle_type {
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.group {
    color: #f89451cb;
    border: 1px solid #f89451cb;
    background-color: #ffece0cb;
  }

  &.user {
    color: $color-primary;
    border: 1px solid $color-primary;
    background-color: #d0e7fdcb;
  }
}

.style_link_auth {
  white-space: pre-wrap;
  word-break: break-all;
  overflow: hidden;
  height: 100% !important;
}

.show-button {
  display: flex;
  align-items: center;
  color: $color-primary;
  border-radius: 3px;
  padding: 0px 2px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    color: #1769c0cb;
  }

  .show-button__icon {
    transform: rotate(00deg);
    transition: transform 0.5s;
  }

  .show-button__icon-active {
    transform: rotate(180deg);
    transition: transform 0.5s;
  }
}

.name-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 140px;

  .name-container__admin-label {
    font-size: 10px;
    color: rgb(237, 139, 109);
  }

  .name-container__root-label {
    font-size: 10px;
    color: rgb(221, 79, 36);
  }

  .name-container__text {
    width: inherit;
    word-wrap: break-word;
  }
}

.container-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 5px 4px 5px;
}

.switch-primary {
  border-radius: 4px !important;
  min-width: 50px;

  &.ant-switch-small {
    height: 18px !important;

    &.ant-switch-checked {
      background-color: $color-green;

      & .ant-switch-inner {
        margin: 0 10px 0 0px;
      }

      & .ant-switch-handle {
        left: calc(100% - 13px - 2px);
      }
    }
  }

  .ant-switch-handle {
    height: 14px !important;
    width: 15px !important;
    border-radius: 2px !important;
    top: 2px !important;

  }

  .ant-switch-handle::before {
    border-radius: 2px !important;
    right: 2px !important;
  }

}