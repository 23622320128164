@mixin disabled {
    background-color: #f5f5f5 !important;
    cursor: not-allowed;
    color: rgb(175, 175, 175) !important;
  }
  
  @mixin buttonTime() {
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 25px;
    height: 100%;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  
    &:hover {
      background-color: #f0f8ff;
      color: #1890ff;
    }
  
    &[disabled] {
      @include disabled();
    }
  }
  
  @mixin addTimeBtnRightLeft() {
    outline: none;
    border: none;
    width: 23px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
  
  @mixin buttonTimeWrapper {
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    background-color: white;
    border-radius: 3px;
    margin: 0px 2px;
  }

.dailyTextarea_window_input{
    // width: 240px;
    display: flex;
    align-items: center;
    min-height: 30px;
  
    // &.number_of_buttons_0{
    //   width: 100px!important;
    // }
    // &.number_of_buttons_2{
    //   width: 170px!important;
    // }
    // &.number_of_buttons_4{
    //   width: 240px!important;
    // }
    // &.number_of_buttons_7{
    //   width: 338px!important;
    // }
  }
  
  .dailyWorkTimeInput {
    display: flex;
    align-items: center;
  
    & .custom_input_number__input{
      padding: 0 7px;
      height: 32px;
      width: 80px;
     }
  }
  
  .custom_input_number{
    position: relative;
    display: flex;
    align-items: center;
    width: 84px;
  
     & .custom_input_number__input{
      height: 32px;
      width: 83px!important;
      position: absolute!important;
     }
  
     & .custom_input_number__arrow_wrapper{
      position: absolute!important;
      bottom: 1;
      right: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      overflow: hidden;
  
      & :hover{
        // color:  blue !important;
        background-color: rgb(212, 232, 250);
      }
     }
  }

  .button-time-number {
    @include buttonTime();
    background-color: white;
    border: 1px solid #d9d9d9;
    margin: 0px 2px;
    border-radius: 3px;
  }
 
  .button-time-wrapper__title_time-work {
    position: relative;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    // width: 25px;
    height: 100%;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none; 

    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;

    transition: color 0.25s, background-color 0.25s;

    &:hover {
        background-color: #f0f8ff;
        color: #1890ff;
    }
}
.button-time-wrapper-small {
    @include buttonTimeWrapper();
    height: 20px;
  
    & .button-time-wrapper__title {
      @include buttonTime();
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
    }
  
    & .button-time-wrapper__button-left,
    & .button-time-wrapper__button-right {
      @include addTimeBtnRightLeft();
  
      &-small {
        @include addTimeBtnRightLeft();
  
        padding: 0px 4px;
        width: 16px;
      }
  
      &:hover {
        background-color: #f0f8ff;
        color: #1890ff;
      }
  
      &[disabled] {
        @include disabled();
      }
    }
  }
  
  .button-time-wrapper {
    @include buttonTimeWrapper();
    height: 26px;
  
    & .button-time-wrapper__title {
      @include buttonTime();
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
    }
  
    & .button-time-wrapper__button-left,
    & .button-time-wrapper__button-right {
      @include addTimeBtnRightLeft();
  
      &-small {
        @include addTimeBtnRightLeft();
        padding: 0px 4px;
        width: 16px;
      }
  
      &:hover {
        background-color: #f0f8ff;
        color: #1890ff;
      }
  
      &[disabled] {
        @include disabled();
      }
    }
  }

  .text-secondary {
    color: #8c8c8c !important;
  }

.time-orange-color {
    color: #f59322!important;
  }
  
  .time-green-color{
    color: #5ca731!important;
  }

  .startWorkAddBtnsDisabled {
    color: #acaaaa;
    border: none!important;
    border-left: none;
    border-right: none;
  }
  
  .onlyBlueText {
    color: #3e9bffec;
  }
  
  .blueText {
    color: #3e9bffec;
    &:hover {
      color: #3e9bffec;
      background: #cde4fd;
    }
  }

  .segmented_style > .ant-segmented-group > .ant-segmented-item {
    margin: 0px
  }

  .segmented_style > .ant-segmented-group > .ant-segmented-item-selected {
    border: none;
    background-color: white;
  }

  .desiredTimeInAllReports {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(215, 215, 215);
    background-color: rgb(238, 238, 238) !important;
    border-radius: 2px;
    width: 70px;
    height: 30px;
  }