@mixin card-shadows {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

// animations
@mixin smooth-fade-in-up($delay, $duration, $ease) {
  opacity: 0;
  animation-name: smoothFadeInUp;
  animation-timing-function: $ease;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: both;

  @keyframes smoothFadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 3%, 0);
    }

    90% {
      opacity: 0.3;
      transform: none;
    }

    95% {
      opacity: 0.5;
      transform: none;
    }

    100% {
      opacity: 1;
      transform: none;
    }
  }
}

@mixin setTagColor($tag-color, $tag-color-bg) {
  color: $tag-color;
  background: $tag-color-bg;

  &._hovered:hover {
    background: darken($tag-color-bg, 8%);
  }

  & .chevron {
    transition: all 0.25s;

    &._open {
      transform: rotate(-180deg);
    }
  }
}

@mixin selectedVector($color-bg, $color-border) {
  box-shadow: 3px 0 0 0 $color-border inset !important;
  background-color: $color-bg;
}

@mixin getCardBoarder($color) {
  box-shadow: -3px 0 0 0 $color, 0px 2px 15px rgba(0, 0, 0, 0.05) !important;
}

@mixin subtypeRadio($color, $color-selected) {
  color: $color !important;
  // border: solid 1px $color !important;

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba($color, 0.1) !important;
  }

  .ant-radio-inner {
    border-color: $color !important;

    &::after {
      background-color: $color !important;
      width: 20px !important;
      height: 20px !important;
      margin-top: -10px !important;
      margin-left: -10px !important;
    }
  }

  span.ant-radio {
    top: 0 !important;
  }

  .ant-radio-checked::after {
    border-color: $color !important;
  }

  input[type="radio"]:focus {
    outline-color: $color !important;
  }

  &.ant-radio-wrapper-checked,
  &:hover {
    background: $color-selected !important;
  }
  // --------------
  &.ant-radio-wrapper-checked {
    &.ant-radio-wrapper-disabled {
      border-color: $color !important;

      &:hover {
        background: $color-selected !important;
      }

      .ant-radio-disabled + span {
        color: $color;
      }
    }
  }

  &.ant-radio-wrapper-disabled {
    border-color: rgba(0, 0, 0, 0.25) !important;

    &:hover {
      background: none !important;
    }
  }
  // --------------
}

@mixin disabledButtonColor {
  &[disabled],
  &[disabled]:hover {
    color: $color-innactive !important;
    border-color: $color-innactive !important;
    background: #f5f5f5 !important;
  }
}

@mixin getBtnColor($btn-color, $btn-color-hover) {
  color: #fff !important;
  background-color: $btn-color !important;
  border-color: transparent !important;
  line-height: inherit;

  &:hover {
    background-color: $btn-color-hover !important;
  }

  @include disabledButtonColor;
}

@mixin getNotClickableBtnColor($btn-color, $btn-color-hover) {
  color: $btn-color !important;
  background-color: transparent !important;
  cursor: text !important;
  border-color: $btn-color !important;
  line-height: inherit;

  @include disabledButtonColor;
}

@mixin getNotClickableBtnOutlineColor($btn-color, $btn-color-hover) {
  color: $btn-color !important;
  border-color: $btn-color !important;

  @include disabledButtonColor;
}

@mixin getBtnOutlineColor($btn-color, $btn-color-hover) {
  color: $btn-color !important;
  border-color: $btn-color !important;

  &:hover {
    color: #fff !important;
    background-color: $btn-color-hover !important;
    border-color: $btn-color-hover !important;
  }

  @include disabledButtonColor;
}

@mixin getBtnSelectColor($btn-color, $btn-color-hover, $btn-color-onHover) {
  color: #fff !important;
  border-color: $btn-color-hover !important;
  background-color: $btn-color-hover !important;

  &:hover {
    color: white !important;
    border-color: $btn-color-onHover !important;
    background-color: $btn-color-onHover !important;
  }

  @include disabledButtonColor;
}

@mixin getBtnLinkColor($btn-color, $btn-color-hover) {
  background: none !important;
  border: none !important;
  color: $btn-color !important;
  box-shadow: none !important;
  padding: 0 !important;
  outline: none !important;
  animation-duration: 0s !important;
  transition: all 0.15s ease-in-out !important;

  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  &:hover {
    color: $btn-color-hover !important;
  }
}

@mixin getBtnCircleColor($btn-color, $btn-color-hover) {
  color: $btn-color !important;
  animation-duration: 0s !important;
  transition: all 0.15s ease-in-out !important;
  outline: none !important;
  border: none !important;
  background-color: rgb(252, 249, 244) !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  padding: 0px;

  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  &:hover {
    color: $btn-color-hover !important;
  }
}

@mixin getBackColor($color, $color-bg, $color-border) {
  color: $color;
  border-radius: 6px;
  border: 1px solid $color-border;
  background-color: $color-bg;
}

@mixin typeCollapse($color, $color-bg) {
  box-shadow: 0 3px 0 0 $color, 0 2px 15px rgba(0, 0, 0, 0.05) !important;

  .collapseComponent-button_container {
    background-color: $color-bg;

    .collapsedText {
      color: $color;
    }
  }
}
