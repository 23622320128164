.blue-base-card {
    border-radius: 6px !important;
    box-shadow: -3px 0 0 0 #3e9bffcb, 0px 2px 15px rgba(0, 0, 0, 0.186) !important;
  
    &.p-0 .ant-card-body {
      padding: 0 !important;
    }
  
    &.p-12 .ant-card-body {
      padding: 12px !important;
    }
  }
  
  .green-base-card {
    border-radius: 6px !important;
    box-shadow: -3px 0 0 0 #22c55e, 0px 2px 15px rgba(0, 0, 0, 0.186) !important;
  
    &.p-0 .ant-card-body {
      padding: 0 !important;
    }
  
    &.p-12 .ant-card-body {
      padding: 12px !important;
    }
  }
  
  .orange-base-card {
    border-radius: 6px !important;
    box-shadow: -3px 0 0 0 #f89451cb, 0px 2px 15px rgba(0, 0, 0, 0.186) !important;
  
    &.p-0 .ant-card-body {
      padding: 0 !important;
    }
  
    &.p-12 .ant-card-body {
      padding: 12px !important;
    }
  }