.events-panel {
  display: grid;
  grid-template-columns: 360px 1fr;
  gap: 24px;
  min-height: calc(100vh - 120px);
}
  
  .glass-effect {
    /* background: rgba(255, 255, 255, 0.95); */
    /* backdrop-filter: blur(10px); */
    border-radius: 16px;
    padding: 24px;
    box-shadow: 
      0 4px 24px -1px rgba(0, 0, 0, 0.05),
      0 0 1px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }
  
  .header-title {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .header-title h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: #2d3748;
    letter-spacing: -0.5px;
  }
  
  .event-count {
    background: rgba(66, 153, 225, 0.1);
    padding: 6px 14px;
    border-radius: 24px;
    color: #4299e1;
    font-size: 13px;
    font-weight: 500;
  }
  
  .events-list, .meta-events-list {
    display: flex;
    background: white;
    flex-direction: column;
    gap: 16px;
  }
  
  .event-card {
    display: flex;
    align-items: flex-start;
    padding: 12px 24px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0;
    border: none;
    border-radius: 0;
  }
  
  .event-card:hover {
    transform: translateY(-2px);
    box-shadow: 
      0 8px 24px rgba(149, 157, 165, 0.1),
      0 0 0 1px rgba(66, 153, 225, 0.1);
    background: rgba(255, 255, 255, 0.95);
  }
  
  .selected-card {
    background: #f8faff !important;
    z-index: 2;
    box-shadow: 
      inset 3px 0 0 #4299e1,
      0 4px 12px rgba(66, 153, 225, 0.1) !important;
  }
  
  .event-card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 10px;
    margin-right: 16px;
    font-size: 20px;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .event-card-icon::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    background: inherit;
    filter: blur(8px);
    opacity: 0.4;
    z-index: -1;
  }
  
  .event-card-content {
    flex: 1;
  }
  
  .event-card-content h3 {
    margin: 0 0 4px;
    font-size: 14px;
    font-weight: 600;
    color: #2d3748;
    letter-spacing: -0.3px;
  }
  
  .event-card-content p {
    margin: 0;
    font-size: 13px;
    color: #718096;
    line-height: 1.5;
  }
  
  .meta-count {
    font-size: 13px;
    font-weight: 500;
  }
  
  .meta-event-card {
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 10px;
    padding-top: 16px;
    padding-left: 16px;
    background: white;
    border: 1px solid rgba(226, 232, 240, 0.8);
    margin-bottom: 16px;
  }
  
  .meta-event-card:last-child {
    margin-bottom: 0;
  }
  
  .meta-event-card:hover {
    transform: translateY(-2px);
    box-shadow: 
      0 8px 24px rgba(149, 157, 165, 0.1),
      0 0 0 1px rgba(66, 153, 225, 0.1);
  }
  
  .meta-event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .meta-event-header h3 {
    font-size: 16px;
    font-weight: 600;
    color: #2d3748;
    margin: 0;
  }
  
  .meta-event-description {
    color: #4a5568;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 16px;
  }
  
  .meta-event-description-label {
    color: #94a3b8;
    font-size: 13px;
    margin-bottom: 4px;
  }
  
  .meta-event-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    border-top: 1px solid #e5e7eb;
  }
  
  .meta-event-info {
    display: flex;
    gap: 24px;
  }
  
  .meta-event-info .meta-event-brief-info {
    margin: 0;
  }
  
  .meta-event-actions {
    display: flex;
    gap: 8px;
  }
  
  .meta-event-footer .date-info {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #718096;
    font-size: 13px;
  }
  
  .meta-event-footer .date-info .anticon {
    color: #4299e1;
    font-size: 14px;
  }
  
  .meta-event-footer .date-info span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .meta-event-footer .anticon {
    color: #4299e1;
    font-size: 16px;
  }
  
  /* Стили для пустого состояния */
  .empty-state {
    margin: 24px;
    padding: 40px;
    background: #f8fafc;
    border: 1px dashed #e2e8f0;
    border-radius: 12px;
    text-align: center;
  }
  
  .empty-state .anticon {
    font-size: 32px;
    color: #a0aec0;
    margin-bottom: 12px;
  }
  
  .empty-state p {
    margin: 0;
    color: #718096;
    font-size: 14px;
  }
  
  /* Стили для поиска */
  .search-box {
    padding: 12px 24px;
    background: #f1f5f9;
    /* border-bottom: 1px solid rgba(226, 232, 240, 0.8); */
  }
  
  .search-box .ant-input-affix-wrapper {
    border: none;
    background: white;
    border-radius: 8px;
    box-shadow: 
      0 2px 4px rgba(0, 0, 0, 0.02),
      0 1px 2px rgba(0, 0, 0, 0.03);
    padding: 8px 12px;
    transition: all 0.2s ease;
    height: 38px;
  }
  
  .search-box .ant-input-affix-wrapper:hover {
    transform: translateY(-1px);
    box-shadow: 
      0 4px 6px rgba(0, 0, 0, 0.04),
      0 2px 4px rgba(0, 0, 0, 0.03);
  }
  
  .search-box .ant-input-affix-wrapper:focus,
  .search-box .ant-input-affix-wrapper-focused {
    transform: translateY(-1px);
    box-shadow: 
      0 4px 6px rgba(0, 0, 0, 0.04),
      0 2px 4px rgba(0, 0, 0, 0.03),
      0 0 0 3px rgba(66, 153, 225, 0.15);
  }
  
  .search-box .ant-input {
    font-size: 14px;
    background: transparent;
  }
  
  .search-box .ant-input::placeholder {
    color: #94a3b8;
  }
  
  .search-box .anticon {
    font-size: 16px;
    color: #64748b;
  }
  
  .events-types .section-header {
    padding: 16px 24px;
    background: white;
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
    margin-bottom: 0;
  }
  
  .events-list {
    /* padding-top: 12px; */
    background: white;
  }
  
  .trigger-section {
    margin-bottom: 4px;
  }
  
  .trigger-section:last-child {
    margin-bottom: 0;
  }
  
  .trigger-section-header {
    padding: 6px 24px;
    font-size: 11px;
    font-weight: 500;
    color: #94a3b8;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    background: #f8fafc;
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
    border-top: 1px solid rgba(226, 232, 240, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .trigger-section-header .collapse-button {
    color: #94a3b8;
    font-size: 12px;
    padding: 2px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
  }
  
  .trigger-section-header .collapse-button:hover {
    color: #64748b;
  }
  
  .trigger-section-header .collapse-button .anticon {
    transition: transform 0.3s ease;
  }
  
  .trigger-section-header .collapse-button.collapsed .anticon {
    transform: rotate(-90deg);
  }
  
  /* Добавим стили для кнопок действий */
  .meta-event-actions {
    display: flex;
    gap: 8px;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
  
  .meta-event-actions .ant-btn {
    padding: 4px 8px;
    height: 32px;
    font-size: 13px;
    border-radius: 6px;
  }
  
  .meta-event-actions .ant-btn-text {
    color: #64748b;
  }
  
  .meta-event-actions .ant-btn-text:hover {
    background: #f1f5f9;
    color: #475569;
  }
  
  .meta-event-actions .delete-btn {
    color: #e11d48;
  }
  
  .meta-event-actions .delete-btn:hover {
    color: #be123c;
    background: #fef2f2;
  }
  
  .meta-event-actions .delete-btn .anticon {
    color: #e11d48;
  }
  
  .meta-event-actions .delete-btn:hover .anticon {
    color: #be123c;
  }
  
  /* Стили для активного метасобытия */
  .meta-event-card.active {
    background: white;
    border: 1px solid rgba(66, 153, 225, 0.3);
    box-shadow: 
      0 2px 4px rgba(66, 153, 225, 0.05),
      0 0 0 1px rgba(66, 153, 225, 0.1);
  }
  
  .meta-event-card.active:hover {
    transform: none;
    box-shadow: 
      0 2px 4px rgba(66, 153, 225, 0.05),
      0 0 0 1px rgba(66, 153, 225, 0.1);
  }
  
  /* Индикатор активности */
  .meta-event-header .active-indicator {
    font-size: 12px;
    color: #4299e1;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
  }
  
  .meta-event-header .active-indicator .anticon {
    font-size: 12px;
    color: #4299e1;
  }
  
  /* Кнопка "Сделать активным" */
  .meta-event-actions .make-active-btn {
    color: #4299e1;
  }
  
  .meta-event-actions .make-active-btn:hover {
    color: #3182ce;
    background: #f8fafc;
  }
  
  .meta-event-actions .make-active-btn .anticon {
    color: #4299e1;
  }
  
  .meta-event-actions .make-active-btn:hover .anticon {
    color: #3182ce;
  }
  
  /* Правая панель по высоте контента */
  .meta-events.glass-effect {
    height: fit-content;
    align-self: start;
    background: white;
    padding: 0;
    border-radius: 0;
  }
  
  .events-types.glass-effect {
    background: white;
    padding: 0;
    border: 1px solid rgba(226, 232, 240, 0.8);
    border-radius: 0;
    height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
  }
  
  .events-list {
    flex: 1;
    overflow-y: auto;
    background: white;
    scrollbar-width: thin;
    scrollbar-color: #e2e8f0 transparent;
  }
  
  .events-list::-webkit-scrollbar {
    width: 6px;
  }
  
  .events-list::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .events-list::-webkit-scrollbar-thumb {
    background-color: #e2e8f0;
    border-radius: 3px;
  }
  
  .events-list::-webkit-scrollbar-thumb:hover {
    background-color: #cbd5e1;
  }
  
  .events-types .section-header {
    padding: 16px 24px;
    background: white;
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
    margin-bottom: 0;
  }
  
  .events-list {
    display: flex;
    flex-direction: column;
    gap: 1px;
    background: rgba(226, 232, 240, 0.8);
  }
  
  .event-card {
    display: flex;
    align-items: flex-start;
    padding: 16px 24px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0;
    border: none;
    border-radius: 0;
  }
  
  .event-card:first-child {
    border-radius: 0;
  }
  
  .event-card:last-child {
    border-radius: 0;
  }
  
  /* Стили для списка метасобытий */
  .meta-events.glass-effect {
    height: fit-content;
    align-self: start;
    background: white;
    padding: 0;
    border-radius: 0;
  }
  
  .meta-events .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
  }
  
  .meta-events .header-title {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 0;
  }
  
  .meta-events .header-title span {
    font-size: 20px;
    font-weight: 600;
    color: #2d3748;
  }
  
  .meta-events .header-subtitle {
    font-size: 20px;
    font-weight: 600;
    color: #2d3748;
  }
  
  .meta-events .ant-list {
    padding: 24px;
  }
  
  .meta-event-card {
    padding: 10px;
    padding-top: 20px;
    padding-left: 16px;
    background: white;
    border: 1px solid rgba(226, 232, 240, 0.8);
    border-radius: 0;
    transition: all 0.3s ease;
    margin-bottom: 16px;
  }
  
  .meta-event-card:last-child {
    margin-bottom: 0;
  }
  
  .meta-event-card:hover {
    transform: translateY(-2px);
    box-shadow: 
      0 8px 24px rgba(149, 157, 165, 0.1),
      0 0 0 1px rgba(66, 153, 225, 0.1);
  }
  
  .meta-event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .meta-event-header h3 {
    font-size: 16px;
    font-weight: 600;
    color: #2d3748;
    margin: 0;
  }
  
  .meta-event-description {
    color: #4a5568;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 16px;
  }
  
  .meta-event-description-label {
    color: #94a3b8;
    font-size: 13px;
    margin-bottom: 4px;
  }
  
  .meta-event-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    border-top: 1px solid #e5e7eb;
  }
  
  .meta-event-info {
    display: flex;
    gap: 24px;
  }
  
  .meta-event-info .meta-event-brief-info {
    margin: 0;
  }
  
  .meta-event-actions {
    display: flex;
    gap: 8px;
  }
  
  .meta-event-footer .date-info {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #718096;
    font-size: 13px;
  }
  
  .meta-event-footer .date-info .anticon {
    color: #4299e1;
    font-size: 14px;
  }
  
  .meta-event-footer .date-info span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .meta-event-footer .anticon {
    color: #4299e1;
    font-size: 16px;
  }
  
  /* Стили для пустого состояния */
  .empty-state {
    margin: 24px;
    padding: 40px;
    background: #f8fafc;
    border: 1px dashed #e2e8f0;
    border-radius: 12px;
    text-align: center;
  }
  
  .empty-state .anticon {
    font-size: 32px;
    color: #a0aec0;
    margin-bottom: 12px;
  }
  
  .empty-state p {
    margin: 0;
    color: #718096;
    font-size: 14px;
  }
  
  /* Стили для поиска */
  .search-box {
    padding: 12px 24px;
    background: #f1f5f9;
    /* border-bottom: 1px solid rgba(226, 232, 240, 0.8); */
  }
  
  .search-box .ant-input-affix-wrapper {
    border: none;
    background: white;
    border-radius: 8px;
    box-shadow: 
      0 2px 4px rgba(0, 0, 0, 0.02),
      0 1px 2px rgba(0, 0, 0, 0.03);
    padding: 8px 12px;
    transition: all 0.2s ease;
    height: 38px;
  }
  
  .search-box .ant-input-affix-wrapper:hover {
    transform: translateY(-1px);
    box-shadow: 
      0 4px 6px rgba(0, 0, 0, 0.04),
      0 2px 4px rgba(0, 0, 0, 0.03);
  }
  
  .search-box .ant-input-affix-wrapper:focus,
  .search-box .ant-input-affix-wrapper-focused {
    transform: translateY(-1px);
    box-shadow: 
      0 4px 6px rgba(0, 0, 0, 0.04),
      0 2px 4px rgba(0, 0, 0, 0.03),
      0 0 0 3px rgba(66, 153, 225, 0.15);
  }
  
  .search-box .ant-input {
    font-size: 14px;
    background: transparent;
  }
  
  .search-box .ant-input::placeholder {
    color: #94a3b8;
  }
  
  .search-box .anticon {
    font-size: 16px;
    color: #64748b;
  }
  
  .events-types .section-header {
    padding: 15px 24px;
    background: white;
    border-bottom: none;
    margin-bottom: 0;
  }
  
  .events-list {
    /* padding-top: 12px; */
    background: white;
  }
  
  .trigger-section {
    margin-bottom: 4px;
  }
  
  .trigger-section:last-child {
    margin-bottom: 0;
    padding-bottom: 4px;
  }
  
  .trigger-section-header {
    padding: 6px 24px;
    font-size: 11px;
    font-weight: 500;
    color: #94a3b8;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    background: #f8fafc;
    border-bottom: 1px solid rgba(226, 232, 240, 0.8);
    border-top: 1px solid rgba(226, 232, 240, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .trigger-section-header .collapse-button {
    color: #94a3b8;
    font-size: 12px;
    padding: 2px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
  }
  
  .trigger-section-header .collapse-button:hover {
    color: #64748b;
  }
  
  .trigger-section-header .collapse-button .anticon {
    transition: transform 0.3s ease;
  }
  
  .trigger-section-header .collapse-button.collapsed .anticon {
    transform: rotate(-90deg);
  }
  
  /* Добавим стили для кнопок действий */
  .meta-event-actions {
    display: flex;
    gap: 8px;
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
  
  .meta-event-actions .ant-btn {
    padding: 4px 8px;
    height: 32px;
    font-size: 13px;
    border-radius: 6px;
  }
  
  .meta-event-actions .ant-btn-text {
    color: #64748b;
  }
  
  .meta-event-actions .ant-btn-text:hover {
    background: #f1f5f9;
    color: #475569;
  }
  
  .meta-event-actions .delete-btn {
    color: #e11d48;
  }
  
  .meta-event-actions .delete-btn:hover {
    color: #be123c;
    background: #fef2f2;
  }
  
  .meta-event-actions .delete-btn .anticon {
    color: #e11d48;
  }
  
  .meta-event-actions .delete-btn:hover .anticon {
    color: #be123c;
  }
  
  .meta-event-card.active {
    background: white;
    border: 1px solid rgba(66, 153, 225, 0.3);
    box-shadow: 
      0 2px 4px rgba(66, 153, 225, 0.05),
      0 0 0 1px rgba(66, 153, 225, 0.1);
  }
  
  .meta-event-card.active:hover {
    transform: none;
    box-shadow: 
      0 2px 4px rgba(66, 153, 225, 0.05),
      0 0 0 1px rgba(66, 153, 225, 0.1);
  }
  
  /* Индикатор активности */
  .meta-event-header .active-indicator {
    font-size: 12px;
    color: #4299e1;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
  }
  
  .meta-event-header .active-indicator .anticon {
    font-size: 12px;
    color: #4299e1;
  }
  
  /* Кнопка "Сделать активным" */
  .meta-event-actions .make-active-btn {
    color: #4299e1;
  }
  
  .meta-event-actions .make-active-btn:hover {
    color: #3182ce;
    background: #f8fafc;
  }
  
  .meta-event-actions .make-active-btn .anticon {
    color: #4299e1;
  }
  
  .meta-event-actions .make-active-btn:hover .anticon {
    color: #3182ce;
  }
  
  .meta-events .ant-spin-nested-loading {
    padding: 0;
  }
  
  .meta-events .ant-spin {
    max-height: none;
  }
  
  .meta-events .ant-list {
    padding: 24px;
  }
  
  .meta-events .ant-spin-container {
    padding: 0;
  }
  
  .meta-events .event-count {
    background: rgba(66, 153, 225, 0.1);
    padding: 4px 12px;
    border-radius: 24px;
    color: #4299e1;
    font-size: 13px;
    font-weight: 500;
    margin-left: 8px;
  }
  
  /* Убираем дублирующие стили */
  .meta-events .event-count {
    margin-left: 8px;
    font-size: 13px;
    font-weight: 500;
    color: #4299e1;
    padding: 6px 14px;
  }
  
  /* Общий стиль для заголовков */
  .meta-events .header-title span {
    font-size: 20px;
    font-weight: 600;
    color: #2d3748;
  }
  
  /* Переопределяем стили для счетчика, чтобы они не наследовались от заголовка */
  .meta-events .header-title .event-count {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #4299e1 !important;
    background: rgba(66, 153, 225, 0.1);
    padding: 6px 14px;
    border-radius: 24px;
    margin-left: 8px;
  }
  
  /* Стил для подзаголовка с названием триггера */
  .meta-events .header-title .header-subtitle {
    font-size: 20px;
    font-weight: 600;
    color: #2d3748;
  }
  
  .trigger-section-content {
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 1000px;
  }
  
  .trigger-section-content.collapsed {
    max-height: 0;
    padding: 0;
    margin: 0;
  }
  
  .meta-event-card {
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .meta-event-card:not(.expanded):hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  
  .meta-event-card.expanded {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .meta-event-actions {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .meta-event-card.expanded .meta-event-actions {
    opacity: 1;
  }
  
  .meta-event-header .title-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  
  .meta-event-brief-info {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #718096;
    font-size: 13px;
    margin-top: 4px;
  }
  
  .meta-event-brief-info .anticon {
    font-size: 14px;
    color: #4299e1;
  }
  
  .meta-event-brief-info span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
  }
  
  .meta-event-brief-info .ant-tag {
    margin-right: 4px;
    font-size: 12px;
    padding: 0 6px;
    line-height: 18px;
    background: rgba(66, 153, 225, 0.1);
    border-color: transparent;
    color: #4299e1;
  }
  
  .title-section h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #1f2937;
  }
  
  .meta-event-controls {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .expand-icon {
    font-size: 12px;
    color: #8c8c8c;
    transition: transform 0.3s ease;
    padding: 8px;
    border-radius: 4px;
  }
  
  .expand-icon:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .expand-icon.expanded {
    transform: rotate(90deg);
  }
  
  .meta-event-card:not(.expanded):hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  
  .meta-event-card.expanded {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .meta-event-actions {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .meta-event-card.expanded .meta-event-actions {
    opacity: 1;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 0;
  }
  
  .meta-event-card .ant-form-item:last-child {
    margin-bottom: 0;
  }
  
  .meta-event-card .ant-form-item-label {
    padding-bottom: 4px;
  }
  
  .meta-event-card .ant-form-item-label > label {
    color: #4a5568;
    font-size: 14px;
    height: auto;
  }
  
  .meta-event-card .ant-input,
  .meta-event-card .ant-input-textarea {
    border-radius: 6px;
    border-color: #e2e8f0;
  }
  
  .meta-event-card .ant-input:hover,
  .meta-event-card .ant-input-textarea:hover {
    border-color: #4299e1;
  }
  
  .meta-event-card .ant-input:focus,
  .meta-event-card .ant-input-textarea:focus {
    border-color: #4299e1;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2);
  }
  
  .groups-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .groups-selector .ant-tag {
    cursor: pointer;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
    margin: 0;
  }
  
  .groups-selector .ant-tag:hover {
    border-color: #4299e1;
    color: #4299e1;
  }
  
  .groups-selector .ant-tag.selected {
    background: #4299e1;
    color: white;
    border-color: #4299e1;
  }
  
  .groups-selector .ant-tag.selected:hover {
    opacity: 0.9;
  }
  
  /* Стили для карточки метасобытия */
  .meta-event-card {
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .meta-event-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .meta-event-card .ant-card-body {
    padding: 16px;
  }
  
  /* Иконка разворачивания */
  .expand-icon {
    font-size: 12px;
    color: #8c8c8c;
    transition: transform 0.3s ease;
    padding: 8px;
    border-radius: 4px;
  }
  
  .expand-icon:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .expand-icon.expanded {
    transform: rotate(90deg);
  }
  
  /* Секция инструкции */
  .instruction-section {
    padding: 12px;
    background-color: #fafafa;
    border-radius: 6px;
  }
  
  /* Секция групп */
  .groups-section {
    padding-top: 8px;
    border-top: 1px solid #f0f0f0;
  }
  
  .groups-section .ant-tag {
    margin-bottom: 4px;
  }
  
  /* Footer метасобытия */
  .meta-event-footer {
    padding-top: 12px;
    border-top: 1px solid #f0f0f0;
  }
  
  .modified-date {
    font-size: 12px;
  }
  
  /* Кнопки действий */
  .meta-event-actions .ant-btn {
    padding: 4px 8px;
  }
  
  .meta-event-actions .ant-btn:hover {
    background-color: #f5f5f5;
  }
  
  .meta-event-actions .ant-btn.ant-btn-dangerous:hover {
    background-color: #fff1f0;
  }
  
  /* Информа��ия о создателе */
  .meta-event-creator {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    margin-top: 4px;
  }
  
  .meta-event-creator .anticon {
    font-size: 14px;
  }
  
  /* Секция групп */
  .meta-event-groups {
    margin-top: 16px;
    padding: 12px;
    background-color: #fafafa;
    border-radius: 4px;
  }
  
  .groups-header {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    margin-bottom: 8px;
  }
  
  .groups-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .groups-list .ant-tag {
    margin: 0;
  }
  
  /* Стили для табов */
  .meta-event-card .ant-tabs {
    margin-top: 16px;
  }
  
  .meta-event-card .ant-tabs-nav {
    margin-bottom: 16px;
  }
  
  .meta-event-card .ant-tabs-tab {
    padding: 8px 16px;
  }
  
  .meta-event-card .ant-tabs-tab-btn {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .meta-event-card .ant-tabs-content {
    padding: 16px;
    background-color: #fafafa;
    border-radius: 4px;
  }
  
  /* Стили для событий */
  .metaevent-events .event-content {
    padding: 8px 0;
  }
  
  .metaevent-events .event-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
  }
  
  .metaevent-events .ant-timeline-item-content {
    padding-bottom: 20px;
  }
  
  .metaevent-events .event-details {
    margin-top: 12px;
    background-color: #fafafa;
    padding: 12px;
    border-radius: 6px;
  }
  
  /* Стили для временной шкалы */
  .metaevent-events .ant-timeline-item-head {
    background: transparent;
    border: none;
  }
  
  .metaevent-events .ant-timeline-item-tail {
    border-left: 2px solid #f0f0f0;
  }
  
  /* Стили для карточки события */
  .event-card.ant-card {
    border: 1px solid #f0f0f0;
    border-radius: 4px;
  }
  
  .event-card .ant-card-body {
    padding: 8px 12px;
  }
  
  /* Стили для списка событий */
  .events-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  /* Стили для копируемого текста */
  .event-card .ant-typography-copy {
    font-size: 12px;
    margin-left: 4px;
  }
  
  /* Стили для событий */
  .event-item {
    padding: 12px;
    margin-bottom: 8px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    transition: all 0.2s ease;
  }
  
  .event-item:hover {
    border-color: #4299e1;
    background-color: #f8fafc;
  }
  
  .event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .event-id {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: monospace;
    font-size: 13px;
  }
  
  .event-time {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #718096;
    font-size: 13px;
  }
  
  .event-user {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 0;
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .event-details {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .event-groups, .event-link, .event-owner {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
  }
  
  .vacation-link {
    color: #4299e1;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .vacation-link:hover {
    color: #2b6cb0;
    text-decoration: underline;
  }
  
  .event-item .anticon {
    color: #718096;
    font-size: 14px;
  }
  
  /* Стили для логов */
  .log-item {
    padding: 16px;
    margin-bottom: 12px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    background: white;
    transition: all 0.2s ease;
  }
  
  .log-item:hover {
    border-color: #4299e1;
    box-shadow: 0 2px 8px rgba(66, 153, 225, 0.1);
  }
  
  .log-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .log-action {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .log-action .ant-tag {
    margin: 0;
    padding: 4px 8px;
    font-weight: 500;
    border: none;
  }
  
  .log-time {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #64748b;
    font-size: 13px;
  }
  
  .log-user {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 12px 0;
    padding: 8px 0;
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
    color: #64748b;
  }
  
  /* Стили для значений в логах */
  .log-values {
    border-radius: 8px;
    padding: 2px;
  }
  
  .log-field {
    padding: 12px 16px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .log-field:last-child {
    border-bottom: none;
  }
  
  .log-field.changed {
    position: relative;
  }
  
  .log-field.changed::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background: #3b82f6;
    border-radius: 2px;
  }
  
  .log-field-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }
  
  .log-field-header .ant-typography {
    font-weight: 500;
    color: #475569;
  }
  
  .change-tag {
    margin: 0;
    font-size: 12px;
    padding: 1px 8px;
    background: #dbeafe;
    color: #2563eb;
    border: none;
  }
  
  .old-value {
    margin-bottom: 8px;
    padding: 8px 12px;
    background: #f1f5f9;
    border-radius: 6px;
    color: #64748b;
  }
  
  .new-value {
    padding: 8px 12px;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
  }
  
  .instruction-text {
    white-space: pre-wrap;
    font-size: 13px;
    line-height: 1.6;
    color: #334155;
  }
  
  .instruction-text p {
    margin: 0;
  }
  
  /* Стили для тегов в логах */
  .log-values .ant-tag {
    margin: 0 4px 4px 0;
    padding: 2px 8px;
    border: 1px solid #e2e8f0;
    color: #475569;
  }
  
  .user-groups {
    margin-left: 8px;
  }
  
  /* Стили для заголовка с фильтрами */
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }
  
  .header-content {
    flex: 1;
  }
  
  .header-title {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .header-filters {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  
  .header-filters .ant-select {
    min-width: 160px;
  }
  
  /* Стили для мобильной версии */
  @media (max-width: 768px) {
    .section-header {
      flex-direction: column;
      gap: 16px;
    }
  
    .header-filters {
      flex-direction: column;
      width: 100%;
    }
  
    .header-filters .ant-select {
      width: 100% !important;
    }
  
    .add-button {
      width: 100%;
    }
  }
  
  /* Стили для заголовка */
  .events-header {
  }
  
  .header-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .header-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .header-title {
    font-size: 24px;
    font-weight: 600;
    color: #1f2937;
    margin: 0;
    line-height: 1.2;
  }
  
  .header-subtitle-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .header-subtitle {
    font-size: 15px;
    color: #4b5563;
    background: #f3f4f6;
    padding: 6px 12px;
    border-radius: 6px;
    font-weight: 500;
  }
  
  .event-count {
    background: #e5e7eb;
    color: #4b5563;
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
  }
  
  /* Стили для фильтров */
  .header-filters {
    margin-top: -24px;
    margin-bottom: -24px;
    background: #f9fafb;
    padding: 12px;
    /* border-radius: 8px; */
    border: 1px solid #e5e7eb;
  }
  
  .filter-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .filter-icon {
    color: #6b7280;
    font-size: 16px;
  }
  
  .filter-select {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .filter-select .ant-select-selector {
    border-color: #e5e7eb !important;
  }
  
  /* Адаптивность */
  @media (max-width: 768px) {
    .header-main {
      flex-direction: column;
      gap: 16px;
    }
  
    .create-button {
      width: 100%;
    }
  
    .header-filters .ant-space {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .filter-item {
      width: 100%;
    }
  
    .filter-select {
      width: 100% !important;
    }
  }
  
  .filters-dropdown-card {
    width: 300px;
    padding: 16px;
  }
  
  .filter-section {
    width: 100%;
  }
  
  .filter-section .ant-typography {
    display: block;
    margin-bottom: 4px;
    font-size: 13px;
  }
  
  .filter-section .ant-select {
    width: 100%;
  }
  
  .meta-event-brief-info {
    color: #4a5568;
    font-size: 13px;
  }
  
  .meta-event-brief-info .ant-tag {
    margin: 0;
    background: rgba(66, 153, 225, 0.1);
    border: none;
    color: #4299e1;
  }
  
  .groups-tooltip {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    max-width: 300px;
  }
  
  .groups-tooltip .ant-tag {
    margin: 0;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
  }